import axios from "axios";

const market = "https://apiios.suzuyagroup.com/descript/market/";

const fashion = "https://ssam.suzuyagroup.com/api/product";

const justDescMarket = "https://apiios.suzuyagroup.com/description/market";
const justDescFashion = "https://ssam.suzuyagroup.com/api/descript/fashion";

export async function getMarketDetails(outlet, internal) {
  // console.log("Data Market");
  // console.log(`Data Market outlet : ${outlet}`);
  // console.log(`Data Market internal : ${internal}`);
  const response = await axios.post(market, {
    outlet: outlet,
    internal: internal,
  });
  return response;
}

export async function getFashionDetails(outlet, internal) {
  const response = await axios.post(fashion, {
    outlet: outlet,
    internal: internal,
  });
  return response;
}

export async function getMarketDescription(internal) {
  console.log(internal);
  const response = await axios.post(justDescMarket, {
    internal: internal,
  });
  return response;
}

export async function getFashionDescription(internal) {
  const response = await axios.post(justDescFashion, {
    internal: internal,
  });
  return response;
}
