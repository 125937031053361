<template>
  <div class="container">
    <div class="row mt-4">
      <div class="col-md-6 shadow" style="border-radius: 20px">
        <img :src="img" width="500px" style="object-fit: contain" />
      </div>
      <div class="col-md-6 mt-4">
        <h1>{{ title }}</h1>
        <br />
        <h4>
          Harga : <strong>Rp {{ numberWithCommas(harga) }} </strong>
        </h4>
        <p>
          Brand : {{ brand }} <br />
          Kategori : {{ kategori }}, {{ subkategori }}
        </p>

        <br />
        <h6>Deskripsi:</h6>
        <ul>
          <li v-for="item in deskripsi" :key="item.id" v-html="item"></li>
        </ul>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { getMarketDetails } from "../services/index";
// import { Watermark } from "@watermarkify/vue-watermark";
import { watermark } from "@yqffqy/watermark";
import Footer from "../components/Footer.vue";
// import { numberWithCommas } from "../utils/index";

// const watermarkOptions = ref({
//   content: "watermark",
//   gap: [20, 20],
//   offset: [10, 10],
//   zIndex: 5,
//   rotate: -20,
// });

export default {
  name: "DescriptionsMarket",
  components: {
    watermark,
    Footer,
  },
  data: () => ({
    data: [],
    img: "",
    title: "",
    harga: 0,
    brand: "",
    kategori: "",
    deskripsi: "",
    subkategori: "",
  }),
  mounted() {
    // console.log(this.$route.params.outlet);
    // console.log(this.$route.params.internal);
    this.getDetails();
  },

  methods: {
    getDetails() {
      getMarketDetails(this.$route.params.outlet, this.$route.params.internal)
        .then((res) => {
          // console.log(res);
          if (res.status == 200) {
            this.data = res.data.data;
            res.data.data.forEach((element) => {
              (this.img = element.img),
                (this.title = element.title),
                (this.harga = element.rrtlprc),
                (this.kategori = element.category),
                (this.subkategori = element.subcategory),
                (this.brand = element.brand),
                (this.deskripsi = element.description);
            });
            // console.log(this.data);
          } else {
            // console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
