<template>
  <div class="container">
    <div class="row mt-4">
      <div class="col-md-6" style="border-radius: 20px">
        <img
          :src="imgchg"
          class="img-fluid shadow"
          width="450"
          style="object-fit: cover; border-radius: 20px"
        />
        <img
          @click="change(imgitem)"
          v-for="imgitem in data[0].img"
          :key="imgitem.id"
          :src="imgitem"
          class="img-fluid shadow"
          width="80"
          style="
            object-fit: cover;
            border-radius: 8px;
            margin-left: 7px;
            margin-right: 7px;
            margin-top: 20px;
          "
        />

        <!-- <div class="container">
          <watermark text="suzuya" contentWidth="250">data</watermark>
        </div> -->

        <!-- <Watermark :options="watermarkOptions">
          <img :src="data[0].img" width="500px" style="object-fit: contain" />
        </Watermark> -->
        <!-- {{ data[0].title }} -->
        <!-- {{ $route.params.outlet }}
        {{ $route.params.internal }} -->
      </div>
      <div class="col-md-6 mt-4">
        <h1>{{ data[0].title }}</h1>
        <br />
        <h4>
          Harga : <strong>Rp {{ numberWithCommas(data[0].rrtlprc) }} </strong>
        </h4>
        <p>
          Brand : {{ data[0].brand }} <br />
          Kategori : {{ data[0].category }}, {{ data[0].subcategory }}
        </p>

        <h6>Deskripsi:</h6>
        <ul>
          <li
            v-for="item in data[0].description"
            :key="item.id"
            v-html="item"
          ></li>
        </ul>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { fashion, getFashionDetails } from "../services/index";
import Footer from "../components/Footer.vue";
export default {
  name: "DescriptionsFashion",
  components: { Footer },
  data: () => ({
    data: [],
    imgchg: "",
  }),
  mounted() {
    // console.log(this.$route.params.outlet);
    // console.log(this.$route.params.internal);
    this.getDetails();
  },

  methods: {
    getDetails() {
      getFashionDetails(this.$route.params.outlet, this.$route.params.internal)
        .then((res) => {
          console.log(res);
          // if (res.status == 200) {
          //   this.data = res.data.data;
          //   this.imgchg = this.data[0].img[0];
          //   // console.log(this.data);
          // } else {
          //   // console.log(res);
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    change(data) {
      console.log("change image");
      console.log(data);
      this.imgchg = data;
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
