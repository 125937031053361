<template>
  <div class="container">
    <center>
      <d-sm-block class="d-md-none">
        <img
          src="../assets/empty.png"
          width="100%"
          style="margin-top: 20%; margin-bottom: 22%"
        />
        <strong style="margin-top: 20%; margin-bottom: 22%">
          <H2>Product Not Found</H2>
        </strong>
      </d-sm-block>

      <div class="d-none d-md-block">
        <img src="../assets/empty.png" width="50%" />
        <strong>
          <H2>Product Not Found</H2>
        </strong>
      </div>
    </center>
    <br />
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style></style>
