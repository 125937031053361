<template>
  <center class="mt-4">
    <h1>Suzuya Products</h1>
  </center>
</template>

<script>
export default {
  name: "Welcome",
};
</script>

<style></style>
