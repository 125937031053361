import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import DFashionView from "../views/DFashionView.vue";
import DMarketView from "../views/DMarketView.vue";
import MarketView from "../views/MarketView.vue";
import FashionView from "../views/FashionView.vue";
import Welcome from "../views/Welcome.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/fashion/:outlet/:internal",
    name: "DFashionView",
    component: DFashionView,
  },
  {
    path: "",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/market/:outlet/:internal",
    name: "DMarketView",
    component: DMarketView,
  },
  {
    path: "/description/market/:internal",
    name: "MarketView",
    component: MarketView,
  },
  {
    path: "/description/market/:internal",
    name: "MarketView",
    component: MarketView,
  },
  {
    path: "/description/fashion/:internal",
    name: "FashionView",
    component: FashionView,
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
