<template>
  <div class="container">
    <div v-if="data.length == 0">
      <NotFound />
      <br /><br />
    </div>

    <div v-else>
      <!-- this line for mobile -->
      <d-sm-block class="d-md-none">
        <div class="col-md-6 shadow mt-4" style="border-radius: 20px">
          <!-- <img
            :src="img"
            width="320px"
            style="object-fit: cover; align-items: center"
          /> -->
          <zoom-on-hover
            :img-normal="img"
            :img-zoom="img"
            :scale="1.5"
            :disabled="false"
            style="width: 320px; object-fit: cover; align-items: center"
          ></zoom-on-hover>
        </div>
        <div class="col-md-6 mt-4">
          <h1>{{ title }}</h1>
          <br />

          <p>
            Brand : {{ brand }} <br />
            Kategori : {{ kategori }}, {{ subkategori }}
          </p>

          <br />
          <h6>Deskripsi:</h6>
          <ul v-for="item in deskripsi" :key="item.id">
            <p v-html="item"></p>
            <!-- <li v-for="item in deskripsi" :key="item.id" v-html="item"></li> -->
          </ul>
        </div>
      </d-sm-block>
      <!-- this end line for mobile -->
      <!-- this line for dekstop -->
      <div class="d-none d-md-block">
        <div class="row mt-4">
          <div class="col-md-6 shadow" style="border-radius: 20px">
            <zoom-on-hover
              :img-normal="img"
              style="width: 500px; object-fit: cover; align-items: center"
              :img-zoom="img"
              :scale="1.5"
              :disabled="false"
            >
            </zoom-on-hover>
            <!-- <watermark text="hello">
              <img
            :src="img"
            width="320px"
            style="object-fit: cover; align-items: center"
            v-watermark
          />
            </watermark> -->
          </div>
          <div class="col-md-6 mt-4">
            <h1>{{ title }}</h1>
            <br />

            <p>
              Brand : {{ brand }} <br />
              Kategori : {{ kategori }}, {{ subkategori }}
            </p>

            <br />
            <h6>Deskripsi:</h6>
            <ul v-for="item in deskripsi" :key="item.id">
              <p v-html="item" style="text-align: justify"></p>
            </ul>
          </div>
        </div>
      </div>
      <!-- this end line for dekstop -->
    </div>
    <Footer />
  </div>
</template>

<script>
import { getMarketDescription } from "../services/index";
import NotFound from "../components/NotFound.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Market",
  components: {
    NotFound,
    Footer,
  },
  data: () => ({
    data: [],
    img: "",
    title: "",
    // harga: 0,
    brand: "",
    kategori: "",
    deskripsi: "",
    subkategori: "",
  }),
  mounted() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      getMarketDescription(this.$route.params.internal)
        .then((res) => {
          // console.log(res);
          if (res.status == 200) {
            this.data = res.data.data;
            res.data.data.forEach((element) => {
              (this.img = element.img),
                (this.title = element.title),
                (this.harga = element.rrtlprc),
                (this.kategori = element.category),
                (this.subkategori = element.subcategory),
                (this.brand = element.brand),
                (this.deskripsi = element.description);
            });
            // console.log(this.data);
          } else {
            // console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
